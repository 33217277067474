import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import { type ComponentPropsWithRef, type ElementRef, forwardRef } from 'react';

export const LABEL_ELEMENT_TAG = 'label';

const label = tailwindVariants({
  base: 'block font-medium text-grey-10',
  variants: {
    size: {
      small: 'text-12',
      medium: 'text-14',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export type LabelVariants = VariantProps<typeof label>;

export type LabelProps = ComponentPropsWithRef<typeof LABEL_ELEMENT_TAG> & LabelVariants;

export const Label = forwardRef<ElementRef<typeof LABEL_ELEMENT_TAG>, LabelProps>(
  ({ className, htmlFor, size, ...restOfProps }, forwardedRef) => {
    return (
      <label
        className={label({ size, class: classNames(htmlFor ? 'cursor-pointer' : 'cursor-auto', className) })}
        htmlFor={htmlFor}
        ref={forwardedRef}
        {...restOfProps}
      />
    );
  },
);

Label.displayName = 'Label';
